import React from 'react'
import { Box, Flex, Text, Center } from '@chakra-ui/react'
import Button from '../../components/Button'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../contexts/responsive'
import lost from './lost.png'

const NotFound = () => (
  <Center px={responsive('3.125em', '15em')}>
    <Flex alignItems={'center'} flexDirection={responsive('column', 'row')} pt={responsive('2.25em', '7em')} py={responsive('4em', '10em')}>
      <Box width={responsive('100%', '60%')}>
        <BackgroundImage src={lost} ratio={716 / 591} />
      </Box>
      <Box ml={responsive(0, '8.25em')} mr={responsive(0, '3.5em')} width={responsive('100%', '40%')} textAlign="center">
        <Text fontWeight={500} fontSize={responsive('7.25em', '16.625em')} lineHeight={1.2}>404</Text>
        <Text fontWeight={700} fontSize={responsive('1.625em', '3.75em')} letterSpacing="1.35px">不小心迷路了嗎？</Text>
        <Text textAlign="left" fontSize={responsive('0.875em', '1.875em')} letterSpacing="1.8px" color="custom.textGray">
          我們目前無法找到這個頁面，先讓小黑人帶你回到我們的基地，來和 RE:LAB 一起玩耍吧！
        </Text>
        <Button.Outline
          to="/#guide"
          mt={responsive('0.875em', '2.125rem')}
          py={responsive('0.5em', '1rem')}
          fontSize={responsive('1em', '2.125em')}
          width="100%"
        >回秘密基地</Button.Outline>
      </Box>
    </Flex>
  </Center>
)

export default NotFound
